<template>
  <div class="video-modal--docked" ref="videoModalDock">
  </div>
  <b-modal :destroy-on-hide="false" :model-value="showLiveVideoOverlay" class="video-modal" :class="{'settings-mode': settingsMode, 'device-selection-mode': !hasDevice && settingsMode, 'full-screen': meetingMetaData.isFullScreen}" :can-cancel="[]">
    <teleport :to="$refs.videoModalDock" :disabled="!teleport">
      <div class="overlay-video popup-mode" ref="overlayVideo">
        <template v-if="settingsMode">
          <div class="overlay-video__header">
            <div></div>
            <div class="overlay-video__title">Video Meeting Settings</div>
            <button class="button close-button is-danger is-rounded" @click="closeModal" v-if="!hasDevice">
            <span class="icon">
              <i class="mdi mdi-close"></i>
            </span>
            </button>
          </div>
          <div class="meet-settings__container">
            <template v-if="hasDevice">
              <b-message type="is-warning" v-if="!meetingMetaData.kiosk_mode">
                2 way video not setup on this device, the patient will only be able to hear you
              </b-message>
              <b-message type="is-danger" v-if="mediaError">
                {{ mediaErrorText }}
              </b-message>
              <ld-form without-content-wrapper>
                <template #default="{ meta: { valid } }">
                  <input-with-validation b-name="input" field-label="Name" rules="required" v-model="visitorName" class="name-input"></input-with-validation>
                  <input-with-validation b-name="checkbox" v-model="activateVideo" v-if="meetingMetaData.kiosk_mode">Activate Your Video</input-with-validation>
                  <input-with-validation v-if="!meetingMetaData.test_mode" b-name="checkbox" v-model="doorKnock">Door Knock</input-with-validation>
                  <input-with-validation v-if="meetingMetaData.device.ptz_enabled" b-name="checkbox" v-model="isPtz">PTZ</input-with-validation>
                  <div class="buttons-container mt-5">
                    <button class="button is-text is-outlined mr-1" @click="isPreview = false; closeModal()">Cancel</button>
                    <button v-if="meetingPreviewEnabled" class="button is-warning is-outlined mr-4" :disabled="!valid" @click="enterPreview">
                      Preview
                    </button>
                    <button class="button is-success is-outlined" :disabled="!valid" @click="() => { meetingMetaData.test_mode ? joinTest() : joinNormal() }">
                      Join
                    </button>
                  </div>
                </template>
              </ld-form>
            </template>
            <template v-else>
              <video-devices-table :table-id="null" :filters="videoTableFilters" class="device-selection-table" :table-attributes="videoTableAttributes" is-standalone-component></video-devices-table>
              <button class="button is-success join-from-devices-table" v-show="selectedVideoDevice" @click="deviceSelected">Select Video Device</button>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="overlay-video__block">
            <div class="overlay-video__header">
              <div class="overlay-video__title">
                <b-dropdown :disabled="!isPatientCameraPlaying || !$store.state.currentUser.enabled_flags.includes('volume_controls')">
                  <template #trigger>
                    <i class="mdi mdi-dots-vertical"></i>
                  </template>
                  <b-dropdown-item custom class="volume-control">
                    <div>
                      <i class="mdi mdi-volume-high"></i>
                      <span>Volume</span>
                    </div>
                    <lvu-volume-control :model-value="meetingMetaData.device?.state?.metrics?.volume" @volume-change="volumeChange"></lvu-volume-control>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="overlay-video__title-content">
                  <span>Patient Video</span>
                  <span class="overlay-video__patient-name">{{ headerName }}</span>
                </div>
              </div>
              <div class="overlay-video__buttons">
                <button v-if="isPreview" class="join-from-preview button is-success is-rounded close-button" @click="joinFromPreview">
                  join
                </button>
                <button v-if="$route.name != 'live-video.external'" class="button is-primary is-rounded close-button" @click="toggleTeleport">
                  <span class="icon">
                    <i class="mdi" :class="teleport ? 'mdi-dock-window' : 'mdi-picture-in-picture-bottom-right-outline'"></i>
                  </span>
                </button>
                <button class="button is-danger is-rounded close-button" @click="closeVideo">
                  <span class="icon">
                    <i class="mdi mdi-close"></i>
                  </span>
                </button>
                <!--            <div class="tag is-primary" v-if="isOutsideOfPatientPage && !isExternal" @click.stop="openPatientPage">-->
                <!--              <i class="mdi mdi-open-in-new" aria-hidden="true"></i>-->
                <!--              <span>Open Patient Page</span>-->
                <!--            </div>-->
              </div>
            </div>
            <div class="overlay-video__content-wrapper">
              <div class="overlay-video__content">
                <webrtc-view :key="webrtcViewReloadKey" :video-settings="{visitorName, activateVideo, doorKnock}"
                             :meeting-meta-data="meetingMetaData" :is-expanded="isPopupMode" start-automatically
                             ref="webrtcView" @close="closeModal" @hangup="isPreview = false; closeModal()">
                </webrtc-view>
              </div>
            </div>
          </div>
        </template>
      </div>
    </teleport>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import WebrtcView from "@/components/WebrtcView";
import InputWithValidation from "@/components/validation/InputWithValidation.vue";
import LdForm from "@/components/LdForm.vue";
import VideoDevicesTable from "@/views/hospital/video_devices/VideoDevicesTable";
import {FilterType, TableFilter} from "@/models/TableFilter";
import LvuVolumeControl from "@/components/LvuVolumeControl.vue";
import to from "@/lib/to";

export default {
  name: "PatientOverlayVideo",
  components: {LvuVolumeControl, VideoDevicesTable, LdForm, InputWithValidation, WebrtcView},
  computed: {
    ...mapState('LiveVideoStore', ['showLiveVideoOverlay', 'isPatientCameraPlaying', 'isPopupMode', 'isExternal', 'meetingMetaData']),
    meetingPreviewEnabled() {
      const divisionValue = this.meetingMetaData.division_meeting_preview_enabled
      if (divisionValue !== null) {
        return divisionValue
      }
      return this.$store.state.currentHospital.meeting_preview_enabled
    },
    isOutsideOfPatientPage() {
      return this.$route.name != 'patient-monitors.monitoring'
    },
    headerName() {
      if (this.meetingMetaData.test_mode && !this.meetingMetaData.is_preview) {
        return ''
      } else {
        let name = this.$helpers.mask('patient_name', this.meetingMetaData?.patient.display_name)
        let roomName = this.meetingMetaData.patient_monitor?.room?.name || this.meetingMetaData.patient?.room?.name
        return `${name} / ${this.$helpers.mask('room_name', roomName)}`
      }
    },
    hasDevice() {
      return !!this.meetingMetaData.device?.id
    },
    mediaErrorText() {
      if (!this.mediaError) {
        return ''
      }
      // console.debug('mediaError', this.mediaError)
      // if the device has no camera or microphone
      if (this.mediaError.audio != 'denied' && this.mediaError.video != 'denied') {
        return "Couldn't find a microphone and/or camera for this computer. Make sure they are connected and permissions have been granted."
      }
      return `
        Couldn't get access to
        ${ this.mediaError.audio == 'denied' ? 'Microphone' : ''}
        ${ this.mediaError.audio == 'denied' && this.mediaError.video == 'denied' ? ' and ' : '' }
        ${ this.mediaError.video == 'denied' ? 'Camera' : ''}.
        Make sure that ${ this.mediaError.audio == 'denied' && this.mediaError.video == 'denied' ? 'they are both' : "it's" } connected and permissions have been granted.
      `;
    }
  },
  methods: {
    ...mapMutations('LiveVideoStore', ['setIsMinimized','setShowLiveVideoOverlay', 'setIsPopupMode', 'setMeetingMetaData', 'setTestMeetingMetaData']),
    toggleTeleport() {
      this.teleport = !this.teleport
      this.setShowLiveVideoOverlay(!this.showLiveVideoOverlay)
      this.setIsMinimized(this.teleport)
    },
    volumeChange(volume) {
      if (this.meetingMetaData.device.kind != 'trainingvideo') {
        this.$refs.webrtcView.videoRoom.setVolume(volume, this.$http);
      }
    },
    openPatientPage() {
      this.$router.push({ name: 'patient-monitors.monitoring', params: { id: this.monitor.id }})
    },
    closeModal() {
      if (this.skipCloseEvent) {
        return;
      }
      if (!this.isPreview) {
        this.setShowLiveVideoOverlay(false)
      }
      this.initState()
    },
    closeVideo() {
      this.$refs.webrtcView.stopVideo()
      this.initState()
      this.setShowLiveVideoOverlay(false)
    },
    initState() {
      this.isPtz = Object.hasOwn(this.meetingMetaData?.device || {}, 'ptz_enabled') && this.meetingMetaData.device.ptz_enabled !== null ? this.meetingMetaData.device.ptz_enabled : false
      this.settingsMode = true
      this.visitorName = this.$store.state.currentUser.name
      this.activateVideo = Object.hasOwn(this.meetingMetaData || {}, 'kiosk_mode') && this.meetingMetaData.kiosk_mode !== null ? this.meetingMetaData.kiosk_mode : false
      this.doorKnock = true
      this.teleport = false
      this.setIsMinimized(this.teleport)
    },
    checkedDeviceUpdate(rows) {
      if (rows.length > 1) {
        this.videoTableAttributes.checkedRows = [rows.pop()]
      } else {
        this.videoTableAttributes.checkedRows = rows
      }
      this.selectedVideoDevice = this.videoTableAttributes.checkedRows[0];
    },
    isTableChecked(a, b) {
      return a.id == b.id
    },
    deviceSelected() {
      if (this.selectedVideoDevice) {
        this.settingsMode = true
        this.setMeetingMetaData({...this.meetingMetaData, device: this.selectedVideoDevice, kiosk_mode: this.selectedVideoDevice.state?.kiosk_mode})
      }
    },
    setupMoveEvents() {
      let offsetY, offsetX
      const mouseMove = ev => {
        this.$refs.overlayVideo.style.top = (ev.clientY + offsetY) + 'px'
        this.$refs.overlayVideo.style.left = (ev.clientX + offsetX) + 'px'
      }
      this.$refs.overlayVideo.addEventListener('mousedown', ev => {
        if (this.teleport) {
          offsetY = this.$refs.overlayVideo.offsetTop - ev.clientY
          offsetX = this.$refs.overlayVideo.offsetLeft - ev.clientX
          window.document.addEventListener('mousemove', mouseMove)
        }
      })
      document.addEventListener('mouseup', () => {
        window.document.removeEventListener('mousemove', mouseMove)
      })
    },
    async enterPreview() {
      const mediaResults = await this.checkMediaPermissions()
      if (mediaResults.err) {
        this.mediaError = mediaResults
        return false
      }
      this.setMeetingMetaData({ ...this.meetingMetaData, is_preview: true, is_ptz: this.isPtz })
      this.isPreview = true
      this.settingsMode = false
    },
    joinFromPreview() {
      this.joinNormal()
      this.skipCloseEvent = true
      this.webrtcViewReloadKey++
      this.$nextTick(() => {
        this.skipCloseEvent = false
      })
    },
    async joinNormal() {

      const mediaResults = await this.checkMediaPermissions()
      if (mediaResults.err) {
        this.mediaError = mediaResults
        return false
      }
      this.setMeetingMetaData({ ...this.meetingMetaData, is_preview: false, is_ptz: this.isPtz })
      this.isPreview = false;
      this.settingsMode = false
    },
    async joinTest() {
      this.isPreview = false;
      this.settingsMode = false
    },
    async queryExistingPermissions() {
      const descriptors = ['microphone', 'camera']
      return Promise.all(descriptors.map(name => navigator.permissions.query({ name })))
    },
    getMediaStates(permissions) {
      return {
        audio: permissions[0].state,
        video: permissions[1]?.state
      }
    },
    async checkMediaPermissions() {
      const perms = await this.queryExistingPermissions()
      const states = this.getMediaStates(perms)
      
      if (perms.some(p => p.state == 'prompt')) {
        this.$store.commit('toggleLoading', { loadingState: true })
        // console.debug(`asking for media permissions: audio: ${states.audio == 'prompt'}, video: ${states.video == 'prompt' && this.activateVideo}`)
        const [err, mediaStream] = await to(navigator.mediaDevices.getUserMedia({
          audio: states.audio == 'prompt',
          // NOTE: if we ask for video and there is no camera, it will throw an error
          // so don't ask for camera if we don't need it
          video: states.video == 'prompt' && this.activateVideo,
        }));
          this.$store.commit('toggleLoading', { loadingState: false })
        if (err) {
          const p = await this.queryExistingPermissions()
          return Promise.resolve({ err: true, errorName: err.name, ...this.getMediaStates(p) })
        } else {
          mediaStream.getTracks().forEach(track => track.stop())
          return Promise.resolve({ err: false, ...states })
        }
      } else if (perms.some(p => p.state == 'denied')) {
        return Promise.resolve({ err: true, ...states })
      } else if (perms.every(p => p.state == 'granted')) {
        return Promise.resolve({ err: false, ...states })
      }
    },
  },
  beforeMount() {
    this.initState()
  },
  watch: {
    showLiveVideoOverlay(newVal) {
      if (newVal) {
        this.teleport = false
        this.initState()
        this.$nextTick(() => {
          this.setupMoveEvents()
        })
      }
    },
    meetingMetaData: {
      handler(newVal) {
        if (newVal) {
          if (newVal.device?.ptz_enabled) {
            this.isPtz = true
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      skipCloseEvent: false,
      webrtcViewReloadKey: 1,
      isPreview: false,
      teleport: false,
      settingsMode: true,
      visitorName: '',
      activateVideo: true,
      doorKnock: true,
      isPtz: false,
      videoTableAttributes: {
        checkable: true,
        isInnerTable: true,
        headerCheckable: false,
        checkedRows: [],
        'onUpdate:checked-rows': this.checkedDeviceUpdate,
        customIsChecked: this.isTableChecked,
        defaultFilters: {
          is_active: ['false'],
          kind: ['mobilecart', 'mobile3rdparty'],
          service_status: ['in_service']
        },
        filterSettings: {
          forceDefaultFilters: true,
          alwaysAppendDefaultFilters: true
        }
      },
      videoTableFilters: [
        new TableFilter({ name: 'name', displayName: "Name", type: FilterType.TEXT }),
      ],
      selectedVideoDevice: null,
      mediaError: false
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes liveVideo {
  from { opacity: 0.5; }
  to { opacity: 1; }
}
.overlay-video {
  width: auto;
  background: #ffffff;
  position: fixed;
  right: 0;
  top: 90px;
  border-radius: 5px;
  box-shadow: 0px -1px 10px -2px rgba(0, 0, 0, 0.13);
  //transition: all 0.1s linear;
  z-index: 100;
}
.overlay-video__content-wrapper {
  padding-top: 56.25%;
  position: relative;
  opacity: 1;
  transition: all 0.1s linear;
}
.overlay-video__content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  :deep(> div) {
    width: 100%;
    height: 100%;
  }
}
.overlay-video__header {
  padding: 14px 20px 11px;
  border-bottom: 1px solid #f2f5fb;
  background: linear-gradient(to bottom, transparent, #f2f5fa6b);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.close-button i {
  font-size: 17px;
}
.overlay-video__title {
  display: flex;
  align-items: center;
  margin-left: -7px;
  :deep(.dropdown) {
    margin-right: 7px;
  }
}
.overlay-video__title-content {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.overlay-video__patient-name {
  font-size: 11px;
  opacity: 0.9;
  text-transform: none;
  padding-top: 3px;
  font-weight: 500;
  overflow-wrap: anywhere;
}
.overlay-video__title--animated {
  i {
    color: #fe4747;
    animation-name: liveVideo;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}
.overlay-video__buttons {
  display: flex;
  align-items: center;
  .tag {
    font-size: 10px;
    display: flex;
    align-items: center;
    line-height: 1;
    height: 2.1em;
    &:not(:last-child) {
      margin-right: 15px;
    }
    i {
      font-size: 12px;
      padding-right: 3px;
    }
  }
}
.overlay-video.popup-mode {
  position: static;
  width: 60%;
  margin: 0 auto;
}

.video-modal :deep(.modal-content) {
  transition: all 0.1s;
  width: 100% !important;
}

.meet-settings__container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto !important;
  .field-wrapper {
    width: 200px;
  }
}
.name-input :deep {
  input {
    width: auto;
  }
  .help {
    display: none !important;
  }
}
.settings-mode {
  :deep {
    .overlay-video__header {
      justify-content: center;
      padding: 18px 0 15px;
    }
    .modal-content {
      width: 500px !important;
    }
  }
}
.close-button {
  width: 26px !important;
  height: 26px !important;
  margin-left: 10px;
}
.device-selection-mode {
  :deep {
    .overlay-video {
      height: 100% !important;
      overflow-y: hidden !important;
      display: flex;
      flex-direction: column;
      width: auto !important;
      max-width: 1080px;
    }
    .overlay-video__header {
      justify-content: space-between;
      padding: 10px 20px 5px;
    }
    .meet-settings__container {
      flex: 1 1;
      > .device-selection-table {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .b-table {
          flex: 1 1;
          display: flex;
          flex-direction: column;
          .table-wrapper {
            overflow: auto;
            flex: 1 1;
          }
        }
      }
    }
    .modal-content {
      max-width: 1200px !important;
      width: 100% !important;
    }
  }
}
.join-from-devices-table {
  position: absolute;
  bottom: 22px;
}
.volume-control {
  i {
    padding-right: 3px;
  }
}
.video-modal--docked .overlay-video__block {
  background: #FFFFFF;
  box-shadow: 0px -1px 10px -2px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}
.video-modal--docked .overlay-video {
  position: absolute;
  right: 0;
  bottom: 0;
  top: auto;
  margin: 0;
  width: 30%;
  z-index: 9999;
  background: transparent;
  box-shadow: none;
}
.modal.full-screen {
  display: block;
  :deep {
    .modal-content {
      max-width: none !important;
      width: 100% !important;
      height: 100%;
      max-height: none;
      margin: 0;
    }
    .overlay-video {
      width: 100% !important;
      max-width: none;
      height: 100%;
    }
    .meet-settings__container {
      height: 100%;
    }
    video.training-video {
      height: 100%;
      //width: auto;
    }
    .overlay-video__block {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .overlay-video__content-wrapper {
      padding: 0;
      height: 100%;
    }
    .webrtc-view__video-container {
      height: 100%;
    }
  }
}
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
}
.join-from-preview {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0 30px !important;
}
:deep(.field-wrapper) {
  margin-bottom: 12px;
}
</style>
